import Head from 'next/head';
import React from 'react';

import { CorporateContact } from '../../../types/Config/CorporateContact';
import { SocialProfile } from '../../../types/Config/SocialProfile';

interface Props {
  corporateContact: CorporateContact;
  socialProfile: SocialProfile;
}

const ALL_SPACES_REGEX = / /g;

export const LinkedDataJson: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ corporateContact, socialProfile }) => {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            name: 'SMSAPI',
            image: corporateContact.image,
            url: corporateContact.url,
            logo: corporateContact.logo,
            sameAs: socialProfile.sameAs,
            contactPoints: corporateContact.contactPoints?.map(
              (contactPoint) => ({
                '@type': 'ContactPoint',
                ...contactPoint,
              }),
            ),
            address: corporateContact.address
              ? {
                  '@type': 'PostalAddress',
                  ...corporateContact.address,
                }
              : undefined,
            telephone:
              corporateContact.contactPoints &&
              corporateContact.contactPoints[0].telephone.replace(
                ALL_SPACES_REGEX,
                '',
              ),
          }),
        }}
      />
    </Head>
  );
};
