import camelCase from 'lodash/camelCase';
import { useRouter } from 'next/router';

export const usePathname = (): string => {
  const { route, asPath } = useRouter();
  const betweenSquareBracketsRegex = /\[+(.*?)]+/g;
  const matches = route.match(betweenSquareBracketsRegex);
  const routeParamNamesWithMatches =
    matches &&
    matches.map((match) => ({ routeParamName: camelCase(match), match }));

  let pathname: string = route;

  if (routeParamNamesWithMatches) {
    const numberOfAsPathParts = asPath.split('/').length;
    const numberOfPathnameParts = pathname.split('/').length;

    routeParamNamesWithMatches.forEach(({ routeParamName, match }) => {
      numberOfAsPathParts === numberOfPathnameParts
        ? (pathname = pathname.replace(match, `:${routeParamName}`))
        : (pathname = pathname.replace(`/${match}`, ''));
    });
  }

  return pathname;
};
