import React, { useContext } from 'react';
import { MetaTag, NextSeoProps } from 'next-seo/lib/types';
import { NextSeo, ProductJsonLd } from 'next-seo';
import Head from 'next/head';
import { useRouter } from 'next/router';

import ConfigsContext from '../../contexts/configs';
import { useInternationalizedHref } from '../../hooks/useInternationalizedHref';
import { I18nContext } from '../../contexts/i18n';
import { ZaufaneRating } from '../../resources/zaufane';

import { LinkedDataJson } from './LinkedDataJson';

import { usePathname } from '@/hooks/usePathname';

export interface SEOProps extends NextSeoProps {
  socialMediaImagePath?: string;
  rating?: ZaufaneRating | null;
  withoutAggregateRatingLdJson?: boolean;
}

const SEO: React.FunctionComponent<React.PropsWithChildren<SEOProps>> = (
  props,
) => {
  const configs = useContext(ConfigsContext);
  const i18nContext = useContext(I18nContext);
  const internationalizedHref = useInternationalizedHref();
  const pathname = usePathname();
  const { query } = useRouter();
  const currentHref = internationalizedHref(
    pathname,
    i18nContext.currentLocale,
    query,
  );

  const currentRouteConfig = configs.routes.find((route) => {
    return route.page === pathname;
  });

  const serviceKeys = configs.services;

  const currentLanguageDomain = i18nContext?.domains?.find((domain) =>
    domain.locales.includes(i18nContext.currentLocale),
  );

  const ogPath = `${
    currentLanguageDomain?.http ? 'http://' : 'https://'
  }${currentLanguageDomain?.domain}`;

  const ogImagePath = props.socialMediaImagePath
    ? props.socialMediaImagePath.startsWith('http')
      ? props.socialMediaImagePath
      : `${ogPath}${props.socialMediaImagePath}`
    : `${ogPath}/static/images/smsapi.png`;

  const googleSiteVerification = serviceKeys?.googleSiteVerification.map(
    (key) => ({ name: 'google-site-verification', content: key }),
  );

  const siteVerificationMetaTags: MetaTag[] = [
    ...googleSiteVerification,
    {
      name: 'yandex-verification',
      content: serviceKeys.yandexVerification || '',
    },
    {
      name: 'msvalidate.01',
      content: serviceKeys.msvalidate01 || '',
    },
  ];

  if (serviceKeys.ahrefsSiteVerification) {
    siteVerificationMetaTags.push({
      name: 'ahrefs-site-verification',
      content: serviceKeys.ahrefsSiteVerification,
    });
  }

  const languageAlternates = (
    currentLanguageDomain?.locales ?? i18nContext.locales
  )
    .map((locale) => ({
      hrefLang: locale,
      href: internationalizedHref(pathname, locale, query) || '',
    }))
    .filter((alternate) => alternate.href);

  return (
    <>
      <Head>
        {siteVerificationMetaTags.map((metaTag) => (
          <meta
            key={metaTag.content}
            name={metaTag.name}
            content={metaTag.content}
          />
        ))}
      </Head>
      <NextSeo
        {...props}
        openGraph={{
          title: props.title,
          description: props.description,
          url: currentHref,
          site_name: 'SMSAPI',
          images: [{ url: ogImagePath }],
          type: 'website',
        }}
        twitter={{
          handle: '@SMSAPI',
          cardType: 'summary_large_image',
          site: '@SMSAPI',
        }}
        additionalMetaTags={[
          {
            name: 'theme-color',
            content: '#002957',
          },
          {
            name: 'author',
            content: 'SMSAPI',
          },
        ]}
        canonical={currentHref}
        languageAlternates={languageAlternates}
        noindex={
          currentRouteConfig?.noindex ??
          currentRouteConfig?.locales[i18nContext.currentLocale]?.noindex
        }
        nofollow={
          currentRouteConfig?.nofollow ??
          currentRouteConfig?.locales[i18nContext.currentLocale]?.nofollow
        }
      />
      <LinkedDataJson
        corporateContact={configs.corporateContact}
        socialProfile={configs.socialProfile}
      />
      {props.rating && !props.withoutAggregateRatingLdJson && (
        <ProductJsonLd
          productName="SMSAPI"
          aggregateRating={{
            ratingValue: String(props.rating.rating),
            ratingCount: String(props.rating.ratingsCount),
          }}
        />
      )}
    </>
  );
};

export default SEO;
